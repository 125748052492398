(() => {

  const setSustainerCookie = () => {
    localStorage.setItem(cookieKey, true);
    userHasCookie = true;
  }
  const getSustainerCookie = () => localStorage.getItem(cookieKey) != undefined && localStorage.getItem(cookieKey) === 'true';

  const cookieKey = 'isSustainer';
  let userHasCookie = getSustainerCookie();

  new URLSearchParams(window.location.search).has("sustainer") && setSustainerCookie();

  if (!userHasCookie) {
    Array.from(document.querySelectorAll('.sustainer')).forEach(el => el.parentNode.removeChild(el)); 
  }
})()